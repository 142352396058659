<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <router-link
            to="/"
            class="d-flex align-center text-decoration-none mr-2"
          >
            <img src="/images/allview-logo.png" alt="" height="48" class="mr-1" />
            <span class="font-weight-black text-uppercase text-gradient">
              Allview Platform
            </span>
          </router-link>
      <v-list dense nav>
        <!-- <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <div v-if="page && page === '/register' || page === '/' || page === '/home'">
            <v-btn block class="mb-2 button-gradient-half" to="login"> Login </v-btn>
          </div>
          <div v-if="page && page === '/login' || page === '/' || page === '/home'">
            <v-btn block class="button-gradient-half" to="register">
              Register
            </v-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>

    <v-btn class="d-md-none drawer-button" rounded @click="drawer = !drawer">
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <div class="d-none d-md-flex">
      <v-app-bar app height="80">
        <v-container class="py-0 px-0 px-sm-2 fill-height" :fluid="isFluid">
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none mr-2"
          >
            <img src="/images/allview-logo.png" alt="" height="48" class="mr-1" />
            <span class="font-weight-black text-uppercase text-gradient">
              Allview Platform
            </span>
          </router-link>

          <div class="d-none d-md-block">
            <!-- <v-btn
              v-for="(item, index) in menu"
              :key="index"
              text
              :to="item.link"
              class="mx-1"
            >
              {{ item.title }}
            </v-btn> -->
          </div>

          <v-spacer></v-spacer>

          <v-btn v-if="page && (page === '/register' || page === '/' || page === '/home' || page.toLowerCase().indexOf('visitor') > -1 )" large class="mx-1 button-gradient-half" to="/login">
            Login
          </v-btn>
          <v-btn v-if="page && (page === '/login' || page === '/' || page === '/home' || page.toLowerCase().indexOf('visitor') > -1 )" large class="button-gradient-half" to="/register">
            Register
          </v-btn>
        </v-container>
      </v-app-bar>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: null,
      page: null
    };
  },
  async mounted() {
    this.page = window.location.pathname
  }
};

</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 40px;
  right: -20px;
  z-index: 6;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.text-gradient {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
