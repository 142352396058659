<template>
  <v-footer color="transparent">
    <v-container class="py-5">
      <v-row>
        <v-col cols="12" md="3">
          <div class="text-h6 text-lg-h5 font-weight-bold">Navigation</div>
          <div
            style="width: 80px; height: 4px"
            class="mb-5 mt-1 button-gradient"/>
          <div>
            <div v-for="(link, i) in links" :key="i" class="w-half body-1 mb-1">
              <router-link
                v-if="link.to"
                class="text-decoration-none secondary--text text--lighten-2"
                :to="link.to"
                >{{ link.label }}</router-link
              >
              <a
                v-else
                class="text-decoration-none secondary--text text--lighten-2"
                :href="link.href"
                :target="link.target || 'blank'"
                >{{ link.label }}</a
              >

            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="text-h6 text-lg-h5 font-weight-bold">
            Contact Information
          </div>
          <div
            style="width: 80px; height: 4px"
            class="mb-5 mt-1 button-gradient"
          />
          <div class="d-flex mb-2 font-weight-bold">
            <v-icon color="secondary lighten-1" class="mr-2"
              >mdi-map-marker-outline</v-icon>
            C/ Perales S/N 30510 Yecla, Murcia, Spain
          </div>
          <div class="d-flex mb-2">
            <v-icon color="secondary lighten-1" class="mr-2"
              >mdi-phone-outline</v-icon
            >
            <a
              href="#"
              class="text-decoration-none secondary--text text--lighten-2"
              >+34 968 752 040</a
            >
          </div>
          <div class="d-flex mb-2">
            <v-icon color="secondary lighten-1" class="mr-2"
              >mdi-email-outline</v-icon
            >
            <a
              href="mailto:help-platform@allview.eu"
              target = "_blank"
              class="text-decoration-none secondary--text text--lighten-2"
              >help-platform@allview.eu</a
            >
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="text-h6 text-lg-h5 font-weight-bold">Social Networks</div>
          <div
            style="width: 80px; height: 4px"
            class="mb-5 mt-1 button-gradient"
          />

          <div>
            <v-btn
              fab
              small
              class="ml-2 button-gradient"
              href="https://twitter.com/AllviewErasmus"
              target="_blank"
            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="ml-2 button-gradient"
              href="https://www.linkedin.com/company/allview-platform/"
              target="_blank"
            >
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="ml-2 button-gradient"
              href="https://www.instagram.com/allviewplatform/"
              target="_blank"
            >
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="ml-2 button-gradient"
              href="https://www.facebook.com/Allview-Erasmus-Vocational-Excellence-Platform-100934198582293/"
              target="_blank"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" md="3">
          <div class="text-h6 text-lg-h5 font-weight-bold">
            About the project
          </div>
          <div
            style="width: 80px; height: 4px"
            class="mb-5 mt-1 button-gradient"
          />
          <div class="mb-2">
            <v-img
              :src="require('@/assets/erasmusPlus.png')"
              max-width="250"
              contain
            />
          </div>
          <div class="d-flex mb-2 text-decoration-none secondary--text text--lighten-2">
            <v-icon color="secondary lighten-1" class="mr-2"
              >mdi-information-outline</v-icon
            >
            621192-EPP-1-2020-1-ES-EPPKA3-VET-COVE
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <div class="text-center caption text-decoration-none secondary--text text--lighten-2">
        <p>© Allview 2021. All Rights Reserved</p>
        <p>
          The European Commission’s support for the production of this
          publication does not constitute an endorsement of the contents, which
          reflect the views only of the authors, and the Commission cannot be
          held responsible for any use which may be made of the information
          contained there
        </p>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          label: "Home",
          to: "./home",
        },
        {
          label: "About",
          to: "/app/about",
        },
        {
          label: "Help",
          href: location.origin + '/manual.pdf',
          target: "_blank",
        },
        {
          label: "Allview Website",
          href: "https://allview.eu/contact/",
        },
      ],
    };
  },
};
</script>

<style scoped>
.text-gradient {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 50%, #047ec3);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gray {
  color: #222c3a;
}
.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 80%);
}
</style>
